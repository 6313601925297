import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Header = () => {
  return (
    <div className="topnav" id="top">
      <nav className="navbar navbar-dark navbar-expand-lg bg-dark ul-bottom fixed-top clean-navbar">
        <div className="container">
          <StaticImage
            src="../../images/Ewarz-forDark-trans-bg.png"
            width={110}
            //   quality={95}
            //   formats={["AUTO", "WEBP", "AVIF"]}
            alt="ewarz"
            //   style={{ marginBottom: `1.45rem` }}
          />
          <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1">
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navcol-1">
            <ul className="nav navbar-nav mx-auto">
              <li className="nav-item" role="presentation">
                <Link className="nav-link pointer" title="Home" href={'/#top'}>
                  Home
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link className="nav-link pointer" title="About US" href="/#about">
                  About Us
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link className="nav-link pointer" title="Pricing" href="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link className="nav-link pointer" title="Tournaments" href="/#tournaments">
                  Tournaments
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link className="nav-link pointer" href="/frequently-asked-questions">
                  FAQ
                </Link>
              </li>

              <li className="nav-item dropdown">
                {/* <a
                  className="dropdown-toggle nav-link"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  href="#"
                >
                  Contact Us
                </a> */}
                <div className="dropdown-menu" role="menu">
                  <a
                    className="dropdown-item"
                    role="presentation"
                    target="_blank"
                    href="https://discord.gg/5a3HJeP4Bq"
                  >
                    Discord
                  </a>
                  <a
                    className="dropdown-item"
                    role="presentation"
                    target="_blank"
                    href="https://www.facebook.com/EwarzOfficial"
                  >
                    Facebook Page
                  </a>
                  {/* <a className="dropdown-item" role="presentation" href="#">Mail Us</a> */}
                </div>
              </li>
            </ul>
            <button className="btn btn-danger rounded-pill disabled" type="button">
              Get E-Warz App
            </button>
          </div>
        </div>
      </nav>
      <div id="nav-placeholder" className="nav-placeholder bg-dark"></div>
    </div>
  );
};

export default Header;
