import { Link } from 'gatsby';
import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className="ul-top bg-dark text-white">
      {/* <div class="footer-newsletter" data-aos="fade-up">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <h4>Join Our Newsletter</h4>
                            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" />
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

      <div class="footer-top mt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact" data-aos="fade-up">
              <h4>Connect with us</h4>
              <p>
                <a
                  className="btn btn-danger btn-block"
                  target="_blank"
                  href="https://ewarz.freshdesk.com/"
                >
                  Helpdesk
                </a>
                <br />
                <strong>Phone:</strong> +91 8130686532
                <br />
                <strong>Regd. Office :</strong><br /> IYG Entertainment Private Limited, 160/b rajnagar,
                Indore city center, madhya pradesh, IN - 452002
                <br />
                {/* <strong>Branch Office :</strong><br />
                IYG Entertainment Private Limited, Plot No, 76 D, Phase IV Sector 18, Gurugram,
                Haryana 122001
                <br /> */}
                <strong>Email:</strong> support@ewarz.freshdesk.com
                <br />
              </p>
            </div>

            <div class="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="100">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <Link title="Home" href="/">
                    Home
                  </Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <Link title="About us" href="/#about">
                    About us
                  </Link>
                </li>
                {/* <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li> */}
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <Link title="Community Guidelines" href="/community-guidelines">
                    Community Guidelines
                  </Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="200">
              <h4>Legal</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <Link title="Terms and Conditions" href="/terms">
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <Link title="Privacy Policy" href="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                {/* <li>
                  <i class="bx bx-chevron-right"></i> <a href="fair-gaming">Fair Gaming</a>
                </li> */}
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <Link title="Payment Policy" href="/payment-policy">
                    Payment Policy
                  </Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="300">
              <h4>Our Social Networks</h4>
              <div class="social-links mt-3">
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a target={'_blank'} href="https://www.facebook.com/EwarzOfficial">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a target={'_blank'} href="https://discord.com/invite/5a3HJeP4Bq">
                      Discord
                    </a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a target={'_blank'} href="https://linkedin.com/company/iygofficial/">
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container py-4">
        <div className="row">
          <div className="col">
            <div class="copyright">
              &copy; Copyright{' '}
              <strong>
                <span>IYG Entertainment Pvt. Ltd.</span>
              </strong>{' '}
              All Rights Reserved
            </div>
          </div>
          <div className="col">
            <div class="credits float-right">
              <span className="float-right text-right">
                Made with ❤️ by{' '}
                <a
                  className="text-info"
                  target="_blank"
                  href="https://www.facebook.com/itesoftcare/"
                >
                  ITESoftcare
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
